
/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

require( './custom' );
// require('../../../js/owl.carousel.min.js')
// require('./jquery.touchSwipe.min');
// require('./bootstrap-touch-slider/bootstrap-touch-slider');
// window.owlCarousel = require('owl.carousel');

/**
 * We'll load the axios HTTP library which allows us to easily issue requests
 * to our Laravel back-end. This library automatically handles sending the
 * CSRF token as a header based on the value of the "XSRF" token cookie.
 */

window.axios = require( 'axios' );

window.axios.defaults.headers.common[ 'X-Requested-With' ] = 'XMLHttpRequest';
/**
 * Next we will register the CSRF Token as a common header with Axios so that
 * all outgoing HTTP requests automatically have it attached. This is just
 * a simple convenience so we don't have to attach every token manually.
 */

let token = document.head.querySelector( 'meta[name="csrf-token"]' );

if ( token )
{
    window.axios.defaults.headers.common[ 'X-CSRF-TOKEN' ] = token.content;
} else
{
    console.error( 'CSRF token not found: https://laravel.com/docs/csrf#csrf-x-csrf-token' );
}

// window.Vue = require( 'vue' );

/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */

// Vue.component('example', require('./components/ExampleComponent.vue'));

// const app = new Vue({
//     el: '#main-wrapper'
// });
